div.wrapper div.login-button,
div.wrapper div.logout-button,
div.wrapper div.panier-button {
  height: 32px;
  width: 32px;
  background-repeat: repeat;
  background-size: contain;
}

div.wrapper div.login-button {
  background-image: url(../images/login-white.png);
}

div.wrapper div.login-button:hover {
  background-image: url(../images/login-red.png);
}

div.wrapper div.logout-button {
  background-image: url(../images/logout-white.png);
}

div.wrapper div.logout-button:hover {
  background-image: url(../images/logout-red.png);
}

div.wrapper div.panier-button {
background-image: url(../images/panier.png);
}
div.wrapper div.panier-button:hover {
background-image: url(../images/panier-over.png);
}
