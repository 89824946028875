nav ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    column-gap: 2rem;
    padding:1rem;
    margin:0;
  
    width: 100%;
     /*background: rgb(9,35,38);
   background: linear-gradient(0deg, rgba(9,35,38,1) 48%, rgba(26,95,164,0) 100%);
   */
  }
  
  nav ul li {
    width: auto;
    height: 2rem;
  }
  
  nav ul li a {
    width: auto;
    height: 2rem;
    font-family: 'Vujahday Script', cursive;
    font-size: 2rem;
    text-decoration: none;
    color: aliceblue;
  }

  nav ul li a:hover {
    color: rgb(27, 142, 243);
    }