

div.sidebar-filter {
    position: sticky;
    margin-top:0rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    row-gap: 1rem;
    column-gap: 0rem;

    background-color: rgb(255, 255, 255);
    padding: 1rem 1rem 1rem 1rem;
    height:100%;
    width:20rem;
    margin-bottom:2rem;

    -webkit-box-shadow: 2px 2px 10px 1px #8A8A8A;
    box-shadow: 2px 2px 10px 1px #8A8A8A;
    border-right:1px solid #848484;

}


div.sidebar-filter label,
div.sidebar-filter input{
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color:#3d3939;
  font-size:1rem;
  margin-top:0.3rem;
}

div.sidebar-filter div.critere{
width:100%;
}

div.sidebar-filter h2{
    font-family: "Roboto", sans-serif;
  color:#ffffff;
  font-size:1.2rem;
  font-weight: 500;
  margin-top:0.3rem;
  width:100%;
  padding:0.5rem 0.5rem;

  background-image: url(../images/frise-eau.png);
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: repeat-x;
  border-bottom:1px solid #222;
  border-top:1px solid #222;
  background-color:#162453;
}

.base-input.prix{
    width:100%;
    padding:0.25rem;
}

div.critere.couleurs,
div.critere.familles{
  display: flex;
  flex-direction: column;
  width:100%;
  height:auto;
}

div.critere.couleurs div.wrapper,
div.critere.familles div.wrapper,
div.critere.types div.wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  width:100%;
  height:auto;
}

button.famille.active,
button.couleur.active{
  border: 3px solid rgb(34, 34, 34) !important;
}

button.couleur.noir.active{
  border: 3px solid rgb(211, 11, 11) !important;
}

div.familles button.famille,
div.types button.type
{
  font-family: 'Vujahday Script', cursive;
  font-size: 1.2rem;
  border-radius:0.2rem;
  border:1px solid rgb(147, 148, 150);
  width:auto;
  height:2.5rem;
  padding:0.25rem 0.75rem;
}

div.couleurs button.couleur{
  border-radius:0.2rem;
  border:1px solid rgb(147, 148, 150);
  width:4rem;
  height:2rem;
}

button.couleur.noir{
  color:white;
  background-color:black ;
}

button.couleur.blanc{
  color:black;
  background-color:white ;
}

button.couleur.rouge{
  color:white;
  background-color: rgb(206, 52, 18);
 }
 button.couleur.jaune{
  color:black;
  background-color: rgb(246, 238, 3);
 }
 button.couleur.orange{
  color:black;
  background-color: rgb(240, 176, 14);
 }

 div.critere select{
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-size:1rem;
 }

/* SPINNERS et EFFACER */
div.spinners span{
  border-radius:1rem;
  width:auto;
  height:1rem;
  padding:0.2rem 1.8rem 0.2rem;
  color:#162453;
  text-align: center;

  background-image: url('../images/cross-close-black.png');
  background-repeat: no-repeat;
  background-position: 3px;
  background-size: 20px;
}

.sidebarfilter-animation {
  width: 0px;
  height: 50px; /* Hauteur arbitraire pour l'exemple */ /* Couleur de fond arbitraire */
  animation: expandWidth 0.5s forwards; /* Nom de l'animation et durée */
}

@keyframes expandWidth {
  from {
      width: 0px; /* Départ de la largeur à 0px */
  }
  to {
      width: 300px; /* Arrivée de la largeur à 300px */
  }
}