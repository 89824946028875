@font-face {
    font-family: "Luxury-Royale";
    src: url('../fonts/luxury-royale-jf.ttf')  format('truetype');
  }

nav.navigation-mobile{

    position:relative;

    display: grid;
    grid-template-columns: 64px 1fr 64px;


    top:0;
    height:4rem;
    width:100vw;
    margin:0;

    
    background-color: #253663;
    color:white;
    z-index:10;

    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;

}




nav.navigation-mobile > div.logo
{
    background-image:url('../images/texte-passionkoi28.png');
    background-position-x: center;
    background-position-y: center;
    background-size: auto 70%;
    background-repeat: no-repeat;

    width:100%;
    height:100%;
}

nav.navigation-mobile div.burger{
    background-image:url('../images/burger.png');
    background-position-x: center;
    background-position-y: center;
    background-size: 60%;
    background-repeat: no-repeat;
}


nav.navigation-mobile div.loupe{
    background-image:url('../images/loupe.png');
    background-position: center;
    background-position-y: center;
    background-size: 60%;
    background-repeat: no-repeat;
}



.sidebar-menu-mobile{
    position:absolute;
    width:20rem;
    height:calc(100vh - 5rem);
    background-color: #253663;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;

    right:0px;
    z-index:20;
    transition: right 0.3s ease; 
}

.sidebar-menu-hidden {
    right: -20rem; /* Déplacement de la sidebar vers la gauche */
}


.sidebar-menu-mobile ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 1rem;


    list-style-type: none;
    width:100%;
    padding:0.8rem;
}

.sidebar-menu-mobile ul li{
    /*font-family: "Luxury-Royale";
    font-size:1.75rem;
    border-bottom: 1px solid white;
    */  
    width:100%;
}
.sidebar-menu-mobile ul li a{
    text-decoration: none;

    display: flex;
    flex-direction: colurowmn;
    justify-content: flex-start;
    align-items: center;

    width:100%;
    margin-top: 0.3rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;

    font-family: 'Vujahday Script', cursive;
    color: #ffffff;
    font-size: 1.54rem;
    font-weight: 100;


    background-image: url("../images/frise-eau.png");
    background-position: bottom;
    background-size: 90% auto;
    background-repeat: repeat-x;
    background-color: #162453;

    border-bottom: 1px solid #222;
    border-top: 1px solid #222;
}