div.backlayer{
    position:absolute;
    z-index:30;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    width: 100vw;
    background-color: rgb(0,0,0,0.5);
}


form.box.activation-code,
form.message{
    position:relative;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    row-gap: 2rem;

    height:50vh;
    width: 50vw;

    border-radius: 0.5rem;
    border:1px solid #333;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: rgb(255,255,255);

}
form.box.activation-code div.cross,
form.message div.cross
 {
    position:absolute;
    display: flex;
    justify-content: center;
    padding-top:5px;
    padding-right:1px;
    width:2rem;
    height:2rem;
    right:1rem;
    top:1rem;
    border-radius: 1rem;
    background-color: lightgray;
    color:white;
    cursor: pointer;

}

form.box.activation-code p,
form.message p{
    text-align: center;
    line-height: 1.8;
    font-size: 1.25rem;
    width:auto;
}



form.box.activation-code input{
    font-size: 1.5rem;
    width:20rem;
    text-align: center;
}

form.box.activation-code button{
    font-size: 1rem;
    width:auto;
    padding:0.5rem 1rem;
}



/* PAGE CREATION DE COMPTE */
p.error{
    color:red;
}

p.valid{
    color:rgb(15, 96, 188);
}

form.update.informations-personnelles{

}

form.update.informations-personnelles textarea{
width: 15rem;
height: 6rem;
}


/* MESSAGE BOX */
form.message{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:2rem;
    height:auto;
}

form.message img{
   width:250px;
}

div.message.thanks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.message.thanks p{
    /*font-family: 'Vujahday Script', cursive;*/
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0;
}


  button.primary {
    height: 2.5rem;
    width: auto;
    
    margin:0.25rem auto;
    padding:0.5rem 1rem;
    
    font-family: 'roboto';
    font-size:1rem;
    text-transform: uppercase;
    border: none;
    border-radius: 0.25rem;
    background-color: rgb(28, 90, 161);
    color: white;
  }
    
  button.primary:hover {
    cursor:pointer;
    background-color: rgb(29, 70, 121);
  }