.homepage-container{
 
    color:rgb(6, 6, 6);
    text-align: center;
    position:relative;

}



/* ******** */
/*  TITLES  */
/* ******** */
div.title{
    display: flex;
    flex-direction: column;
}

div.title.left{
    align-items: flex-start;
}

div.title.right{
    align-items: flex-end;
}

div.title.center{
    align-items: center;
}

div.title span.big{
    height:auto;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    font-size: 6rem;
    color:rgb(255, 255, 255,0);
}

div.title span.big.full{
    color:rgb(255, 255, 255,0.8);
}


div.title span.little{
    position:relative;
    top:-0.5rem;
    height:auto;
    line-height: 0;
    font-size: 3rem;
    color:black;
    /*font-family: "Roboto", sans-serif;*/
    font-family: 'Vujahday Script', cursive;
    font-weight: 400;
    font-style: normal;
    margin-bottom:3rem;
}

section.mobile div.title span.big{
    font-size: 3rem;
    padding-left:0.5rem;
    padding-right:0.5rem;
}

section.mobile div.title span.little{
    font-size: 1.8rem;
    margin: 0.5rem;
    padding-left:0.2rem;
}

/* ****************** */
/*       SECTIONS     */
/* ****************** */

section{
    width:100%;
    height:auto;
    margin:0;
    position:relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

section p{
    margin:0;
}

section.mobile{
    display: flex;
    flex-direction: column;
    padding:0.5rem;
}

section.desktop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:2rem;
}

/* ****************** */
/*       WRAPPER      */
/* ****************** */

section div.text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height:auto;
    margin:0.5rem;
    padding-bottom:1rem;

    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1.4;

    /*border:1px solid #222;*/
    border-radius: 5px;
    overflow:hidden;

    background-color: rgb(0,0,0,0.6);
    color:#fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
section div.text-wrapper span{
    margin-bottom:0.45rem;
    margin-left:0.5rem;
    margin-right:0.5rem;
    padding-left:0.5rem;
    padding-right:0.5rem;

    font-family: "Roboto", sans-serif;
    font-size:1.25rem;
    font-weight: 300;
    font-style: normal;
    line-height: 1.4;
}


section.mobile div.text-wrapper{
    width:calc(100% - 1rem);
}
section.desktop div.text-wrapper{
    width:45%;
}
section div.text-wrapper div.image{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-repeat: no-repeat;
    width:100%;
    height:10rem;
    margin-bottom:2rem;
}

/* *************************** */
/*       SECTIONS PREFS        */
/* *************************** */
section.utilisation{
    background-color: #d6e9ef;
}
Q
section.evenement{
    background-color: #aad6e4;
}

section.promotion{
    background-color: #96aed5;
    z-index:8;
}

section.carpe-de-la-semaine{
    background-color: #e26023;
    z-index:2;
}

section.les-reseaux{
    background-color: #3a4474;
}

section.la-boutique{
    background-color: #93cbd3;
    z-index:2;
}
/* *************************** */
/*     SECTION UTILISATION     */
/* *************************** */
/* DESKTOP */
section.utilisation.desktop{
    flex-direction: row-reverse;
    justify-content: space-around;
}

section.utilisation.desktop div.text-wrapper{
    width:50%;
}

/* STANDARD */

section.utilisation div.text-wrapper div.image{
    background-image: url('../images/ecran-selecteur-carpes.png');
    background-size: 100%;
    background-position: center top;
}

section.utilisation.desktop div.text-wrapper div.image{
    height:26rem;
}

section.utilisation div.text-wrapper div.image{
    height:10rem;
}

/* ************************* */
/*     SECTION EVENEMENT     */
/* ************************* */

/* DESKTOP */
section.evenement.desktop{
    flex-direction: row;
}

/* STANDARD */
section.evenement div.text-wrapper{
    flex-direction: column;

}

section.evenement div.text-wrapper div.image{
   width:100%;
   height:20rem;
   background-image: url('../images/pk28-bassin-no1.jpg');
   background-size: cover;
   background-position: center;
   margin-bottom: 2rem;
    
}

/* ************************* */
/*     SECTION PROMOTION     */
/* ************************* */

/* DESKTOP */
section.promotion.desktop{
    flex-direction: row-reverse;
}

/* STANDARD */
section.promotion div.text-wrapper{
    flex-direction: column;

}

section.promotion div.text-wrapper div.image{
   width:100%;
   height:20rem;
   background-image: url('../images/produit-filtration-tambour.jpg');
   background-size: cover;
   background-position: center;
   margin-bottom: 2rem;
    
}

/* ************************* */
/*    CARPE DE LA SEMAINE    */
/* ************************* */

section.carpe-de-la-semaine{
    justify-content: flex-start;
    column-gap: 5rem;
    background-image: url('../images/texture-ecailles-rouges.png');
    background-size: 5%;
}

section.carpe-de-la-semaine div.text-wrapper{
background-color: rgb(41, 78, 158,0.9);
}


section.carpe-de-la-semaine.desktop div.text-wrapper{
    margin-top:10.25rem;
    height:40rem;
    width:20rem;
}

section.carpe-de-la-semaine div.image{
    background-color: white;
    background-image: url('../images/koi-modele.jpg');
    background-size: cover;
}

section.carpe-de-la-semaine.desktop div.image{
    height:100%;
}

section.carpe-de-la-semaine.mobile div.image{
    height:30rem;
}


section.carpe-de-la-semaine.mobile span.supplement{
    display:none;
}

section.carpe-de-la-semaine.desktop span.supplement{
    background-color:rgb(255,255,255,0.85);
    height:40rem;
    width:40rem;
    border-radius: 5px;
    border:1px solid #575757;
}

/* ************************* */
/*    LES RESEAUX SOCIAUX    */
/* ************************* */
section.les-reseaux.desktop{
    flex-direction: row-reverse;
    justify-content: space-around;
}


section.les-reseaux span.little{
    color:rgb(255,255,255,0.85);
}

section.les-reseaux div.text-wrapper{
    background:none;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    border:none;
    box-shadow: none;
}

section.les-reseaux div.text-wrapper div.wrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

section.les-reseaux div.text-wrapper a.block{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:50%;
    height: 10rem;
}


section.les-reseaux div.text-wrapper a.block div.text{
    font-family: 'Vujahday Script', cursive;
    color:white;
}

section.les-reseaux div.text-wrapper a.block div.image{
    width:10rem;
    height:10rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-shadow: none;
}

section.les-reseaux div.text-wrapper a.block:nth-child(1) div.image{
    background-image: url('../images/icon-facebook.png');
}

section.les-reseaux div.text-wrapper a.block:nth-child(2) div.image{
    background-image: url('../images/icon-instagram.png');
}

/* ********************* */
/*     LA BOUTIQUE       */
/* ********************* */
section.la-boutique div.bienvenue{
    position:absolute;

    left:2rem;
    top:1.5rem;

    font-size: 1.75rem;
    font-family: 'Vujahday Script', cursive;

}
section.la-boutique div.text-wrapper{

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding:0;
    box-shadow: none;
    background: none;
    padding-top:1rem;
}


section.la-boutique a.block{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

section.la-boutique.desktop div.circle{
    height:10rem;
    width:10rem;

    border-radius: 10rem;
    border:3px solid rgb(255,255,255,1);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: hue-rotate(0deg);
    transition: filter 0.75s ease;

}

section.la-boutique div.circle:hover{
    filter: hue-rotate(180deg);
    cursor: pointer;
    color:rgb(255,255,255,0);
    text-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-color: blue;
}

section.la-boutique div.circle.aquarium{
    background-image: url('../images/circle-carpes.jpg');
}

section.la-boutique div.circle.materiel{
    background-image: url('../images/circle-polymove.jpg');
    background-size: 175%;
}

section.la-boutique div.circle.contact{
    background-image: url('../images/circle-contacter-nous.jpg');

}

section.la-boutique a.block span{
    font-family: 'Vujahday Script', cursive;
    /*font-family: "Roboto", sans-serif;*/
    font-weight: 700;
    font-size: 1.75rem;
    font-style: italic;
    color:#383838;
    margin-top:0.5rem;
}

section.la-boutique.mobile{
    align-items: center;
}

section.la-boutique.mobile div.text-wrapper{
    flex-direction: column;
    align-items: center;
}



section.la-boutique.mobile div.circle{
    height:10rem;
    width:10rem;

    border-radius: 10rem;
    border:2px solid rgb(255,255,255,1);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

section.la-boutique.mobile div.circle{
    width:20rem;
    border-radius: 0.5rem;

}


section.la-boutique div.circle.materiel{
    background-size: 200%;
}

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2f5f6+0,e3eaed+37,c8d7dc+100;Grey+3D+%234 */
/*background: linear-gradient(to bottom,  #f2f5f6 0%,#e3eaed 37%,#c8d7dc 100%);
*/