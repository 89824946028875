div.megamenu{
    position:absolute;

    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    top:15.7rem;
    left:0;
    width:100%;
    height:16rem;
    padding:2rem;

    background-color: rgb(229, 231, 239);
    color:black;
    cursor:default;

}

div.megamenu div.megamenu-wrapper{
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    height:auto;
    width:300px;
}

div.megamenu div.megamenu-wrapper h4{
    font-size: 1.2rem;
    text-transform: uppercase;
    margin:0;
    height:auto;
    width:100%;
}


div.megamenu  div.megamenu-wrapper ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding:0;
    border-left:3px solid black;
    padding-left: 1rem;;
}

div.megamenu  div.megamenu-wrapper ul > li{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    padding:0.15rem 0.15rem;
    cursor:pointer;
    text-transform:lowercase;
}

div.megamenu  div.megamenu-wrapper ul > li:hover{
 text-decoration: underline;
}

.megamenu-animation {
    animation: opacityAnim 0.75s forwards; /* Nom de l'animation et durée */
}

.megamenu-animation:not(:hover) {
    animation: opacityAnimOut 0.75s forwards;
}

@keyframes opacityAnim {
    from {
        opacity: 0; 
    }
    to {
        opacity: 1; 
    }
}

@keyframes opaciopacityAnimOuttyAnim {
    from {
        opacity: 1; 
    }
    to {
        opacity: 0; 
    }
}