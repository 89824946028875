@font-face {
    font-family: 'Luxury Royale';
    src: url('../fonts/luxury-royale-jf.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

section.boutique{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    min-height:100vh;
    height:auto;
    padding-bottom:5rem;
}

section.boutique div.wide{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:50rem;
    height: auto;
}

section.boutique div.categories-container{

    display: flex; /* Utilisation de Flexbox */
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-content: space-between;
    height:auto;
    width: 100%;

}

section.boutique h2.categories-title{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 100%;
    height:4rem;

    font-family: 'Luxury Royale';
    font-weight: 200;
    font-size: 3rem;
    color:white;
    background-color: rgba(13, 62, 221, 0.75);
    
}


section.boutique div.categories-container > a{

    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 15rem;
    height:15rem; /* Chaque élément occupe 1/3 de la largeur */
    box-sizing: border-box;

    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;

    background-size: cover;
    background-repeat: no-repeat;
    background-color: #42b2d7;
    background-position: center;

    border: 1px solid #222;
    border-radius:3px;
}

section.boutique div.categories-container > a  > div{

    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center; 
    
    min-height:2rem;
    height: auto;
    width:100%;
    padding:0.5rem;

    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;

    color:white;
    background-color: rgba(13, 62, 221, 0.75);

}
.categories-container.materiel > a:nth-child(1){
    background-image: url('../images/categories/matériel/pompes-bassins.png');
}
.categories-container.materiel > a:nth-child(2){
    background-image: url('../images/categories/matériel/oxygene.png');
}
.categories-container.materiel > a:nth-child(3){
    background-image: url('../images/categories/matériel/amenagement-de-bassin.png');
}

.categories-container.materiel > a:nth-child(4){
    background-image: url('../images/categories/matériel/filtres-uv-lampes.png');
}
.categories-container.materiel > a:nth-child(5){
    background-image: url('../images/categories/matériel/chauffage-de-bassin.png');
}
.categories-container.materiel > a:nth-child(6){
    background-image: url('../images/categories/matériel/pieces-detachees.png');
}
.categories-container.materiel > a:nth-child(7){
    background-image: url('../images/categories/matériel/etancheite-et-construction.png');
}
.categories-container.materiel > a:nth-child(8){
    background-image: url('../images/categories/matériel/pvc-raccords-et-tuyaux.png');
}

.categories-container.materiel > a:nth-child(9){
    background-image: url('../images/categories/matériel/accessoires-divers-pour-bassin.png');
}


.categories-container.nourriture > a:nth-child(1){
    background-image: url('../images/categories/nourriture/nourritures-de-printemps.png');
}
.categories-container.nourriture > a:nth-child(2){
    background-image: url('../images/categories/nourriture/nourritures-d-ete.png');
}

.categories-container.nourriture > a:nth-child(3){
    background-image: url('../images/categories/nourriture/nourritures-d-automne.png');
}
.categories-container.nourriture > a:nth-child(4){
    background-image: url('../images/categories/nourriture/nourriture-d-hiver.png');
}
.categories-container.nourriture > a:nth-child(5){
    background-image: url('../images/categories/nourriture/nourriture-coulante.png');
}
.categories-container.nourriture > a:nth-child(6){
    background-image: url('../images/categories/nourriture/distributeurs-de-nourritures.jpg');
}

@media screen and (max-width: 770px) {

    section.boutique div.categories-container{
        width: 100%;
    
    }

    section.boutique div.wide{
        width:100vw;
    }

    section.boutique div.categories-container{

        display: flex; /* Utilisation de Flexbox */
        flex-wrap: wrap;
        row-gap: 1rem;
        justify-content: space-around;
        
        height:auto;
        width: 100%;
    
    }
    section.boutique h2.categories-title{
       width:90%;
    }

    section.boutique div.categories-container > a{
        width: 10rem;
        height:10rem; /* Chaque élément occupe 1/3 de la largeur */
    }

  }