 /* ******************* VIGNETTE PRODUIT *************** */
 div.vignette-product {
    width: 15rem;
    height: 32rem;
    
    border:1px solid #123650;
    cursor:pointer;
  
    display: flex;
    flex-direction: column;
    position:relative;

    background: rgb(9,35,38);
    background: radial-gradient(circle, rgba(9,35,38,1) 0%, rgba(26,95,164,1) 100%);
    box-shadow: 2px 2px 10px 1px #8A8A8A;

}

  div.vignette-product:hover{  
    -webkit-box-shadow: 0px 0px 8px 3px rgba(255,255,255,0.25); 
    box-shadow: 0px 0px 5px 5px rgba(255,255,255,0.35);
    border:3px solid #d5350d;
  }


/* PARTIE IMAGE DE LA VIGNETTE */
  div.vignette-product div.image-koi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    height: 100%;
    width: 100%;

    background-repeat: no-repeat;
    background-size: auto 115% ;
    background-position: center;
    background-color: #3390f8;
    overflow:hidden;
  }

    
  div.vignette-product div.addToCart{
    position: relative;
    padding-left:2rem;
    margin:0.5rem 0 0 0.5rem;

    width:8rem;
    height:1.5rem;

    
    background-image: url(../images/panier.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    cursor: pointer;
    color:rgb(255, 255, 255);
  }

  div.vignette-product div.addToCart:hover{
    background-image: url(../images/panier-over.png);
    color:#02fb06;
    text-shadow: rgb(15, 14, 14) 0px 0 10px;
    font-weight: 500;
  }



  div.vignette-product div.informations *{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top:0.2rem;
  }

  
  
  div.vignette-product div.trash{
    position: absolute;
    top:10px;
    right:13px;
    width:1rem;
    height:1.5rem;
    background-image: url(../images/trash.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor:pointer;
  }
  div.vignette-product div.trash:hover{
    background-image: url(../images/trash-red.png);
  }

  

.vignette-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}

.vignette {
  max-width: 80%; 
  max-height: 80%; 
}

