div.panier-container {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;

    width: calc(100vw - 8rem);
    min-height: calc(100vh - 20rem);
    height:auto;
    margin:2rem auto;

    /*border:1px solid #333;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    box-shadow: 2px 2px 10px 1px #8A8A8A;

    z-index: 201;
    overflow: hidden;
    */
  }

  div.panier-container h2 {
    color:#000;
    text-align: center;
  }
  div.panier-container div.wrapper{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    column-gap: 2rem;
    row-gap:2rem;
    justify-content: flex-start;
    align-items: center;

    width:100%;
    height:auto;
    color: #000;
    padding:1rem;
  }
