
/* ************************************* */
/*             FORMULAIRES               */
/* ************************************* */


  form *{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  div.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: auto;
    width: 80vw;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  div.form-container  h2{
    color:#3172ce;
  }

  div.form-container form{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width:50%;
  }


  div.form-container div.input{
    display: flex;
    flex: row;
    align-items: center;
    width:100%;
  }

  div.form-container label{
    text-align: right;
    padding-right:1rem;
    width:10rem;
  }

  div.form-container input{
    width:100%;
    height: auto;
    padding: 0.5rem;
    border: 1px solid #b5b5b5;
    border-radius: 0.25rem;
  }

  div.form-container div.error-message{
    color:red;
  }


/* ************************************* */
/*          FORMULAIRE (login  )         */
/* ************************************* */
  div.form-container.login input{
    width:20rem;
  }

  div.form-container.login form.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
/* ************************************* */
/*     FORMULAIRES (Contactez-nous)      */
/* ************************************* */


div.form-container.contact textarea{
  padding:1rem;
  height:15rem;
  width:100%;
}
@media screen and (max-width: 770px) {
  div.form-container{
    width:100%;
    height:calc(100vh - 4rem);
    padding:0;
    margin:0;
  }

  div.form-container{
    width:100%;
    height:100vh;
    padding:0;
    margin:0;
  }

  div.form-container.contact form{
    width:100%;
    padding:1rem;
  }

  div.form-container.login form{
    justify-content:flex-start;
    width:100%;
    height:calc(100vh - 14rem);
    padding:1rem;
    margin:0;
  }
  div.form-container.login{
   height:100%;
   justify-content: center;
  }

  div.form-container.login a{
    height:3rem;
    margin-bottom:2rem;
  }

}
