
footer {
  position:absolute;
    bottom:-10rem;
    height: 10rem;
    width: 100%;
    background-color: #252424;
  }
  
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  
  footer ul li {
    padding: 1rem;
    color: #ddd;
  }
  
  footer ul div.title {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }