

/* main-container ... */
  div.boutique-container{
    position:sticky;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    top:0;
    height:auto;
    min-height: 100vh;
  }
  
  div.main-products {
    position:relative;
    flex:1;
    height:auto;
  }

  div.products-container {
    width: 100%;
    height:auto;
    padding: 1rem;
    margin: 0;
  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 2rem;
    column-gap: 2rem;
    
  }
 
    div.products-container.mobile {
      justify-content: center;
    }

  div.informations {
    background-color: rgb(255, 255, 255, 0.8);
    color: rgb(55, 58, 60);
    padding: 0.5rem;
  }
  

/* ---------- FICHE PRODUIT ------------ */
div.back-layer{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fond semi-transparent pour un effet d'obscurcissement */
  color: white;
}

div.fiche-produit {
  position: relative;
  top:100px;
  width: 50rem;
  height: 30rem;
  margin:auto;
  border:1px solid #333;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 1);
  color: white;
  z-index: 201;
  overflow: hidden;
}

div.fiche-produit div.divider{
  display:flex;
  height:100%;
}

div.fiche-produit div.image{
  width:30%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-right:1px solid #333;

}

div.fiche-produit div.wrapper{
  width:70%;
}


div.fiche-produit div.informations{
  width:100%;
  height:100%;
  padding:3rem;
}

div.fiche-produit div.informations *{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top:0.2rem;
}

div.fiche-produit div.informations div.wrapper-1{
  position:absolute;

  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  top:3rem;
  right:3rem;
  width:auto;
  height:auto;

}

div.fiche-produit div.famille{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color:#0d81d5;
}

div.fiche-produit div.prix{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color:#039499;
}

div.fiche-produit div.taille{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  color:#0d81d5;
  text-transform:lowercase;
}


div.fiche-produit div.age{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  color:#0d81d5;
  text-transform:lowercase;
}

div.fiche-produit div.description{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1rem;
  font-weight: 100;
  margin-top:1rem;
  max-height: 10rem;
  overflow-y: scroll;
}

div.fiche-produit div.reserver{
  position:absolute;
  right:1rem;
  bottom:1rem;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top:0.2rem;
  text-align: right;

 color:#000;
 font-weight: 400;
 font-size: 1.25rem;
}

div.fiche-produit div.informations div.wrapper-2 button {
  border:none;
  border-radius:0.2rem;
  color:white;
  background-color:#039499;
  padding:0.5rem 1rem;
  margin-top:1rem;
  cursor:pointer;

}
div.fiche-produit div.informations div.wrapper-2 span {
  color:#039499;
}
div.fiche-produit div.informations div.wrapper-2{
  position:absolute;

  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  bottom:2rem;
  right:3rem;
  width:auto;
  height:auto;

}


button.close{
  position:absolute;
  width:30px;
  height:30px;
  top:10px;
  right:10px;
  border:none;
  background-color: white;
  background-image: url(../images/cross-close-black.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor:pointer;
}

button.close:hover{

  background-image: url(../images/cross-close-blue.png);
}
