/* ----------- HEADER MAXIMIZED ----------- */
header.main-header {

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    row-gap: 1rem;

    position:relative;
    height: 250px;
    width:100vw;
    z-index:10;

    background-color: #162453;
    background-image: url(../images/header-pk28-7-hd.jpg);
    background-repeat: no-repeat;
    background-size:auto 100%;
    background-position: top center;

    -webkit-box-shadow: 2px 2px 10px 1px #8A8A8A;
    box-shadow: 2px 2px 10px 1px #8A8A8A;
    transition: height 0.3s ease-in-out;
  }
  
  /* ----------- HEADER MINIMIZED ----------- */
  header.scrolled-header{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
  
    background-color: #162453;
    /*background-image: url(../images/header-pk28-7-hd.jpg);*/
    background-repeat: no-repeat;
    background-size:auto 100%;
    background-position: top center;
    
    -webkit-box-shadow: 2px 2px 10px 1px #8A8A8A;
    box-shadow: 2px 2px 10px 1px #8A8A8A;
  
    position: fixed;
    top:0;
    height: 4rem;
    width:100vw;
    z-index:100;

    transition: height 0.5s ease-in-out;
  }
  
  header.scrolled-header div.wrapper{
    position:absolute;
    right:4rem;
    bottom:1rem;
    display: flex;
    flex-direction: row;
    column-gap:0.75rem;
    width:100px;
  }
  
  header.scrolled-header nav{
    width:50rem;
  }
  
  header.scrolled-header nav li a{
    font-size: 1.75rem;
  }
  
  nav{
    margin-bottom:1rem;
  }
  
  /* ----------- HEADER ... ----------- */
  
  .main-header > .wrapper {
    position: absolute;
    right: 2rem;
    top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    column-gap: 1rem;
  }