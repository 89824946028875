@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

.main-container {
  position:relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin: 0 auto;
  background-color: rgb(211, 202, 200);
  background-image: url(../images/texture-ecailles-light.jpg);
  background-repeat: repeat;
  background-size: 60px 60px;
  overflow:hidden;

}

.shop-title {
  text-align: center;
  font-family: 'Vujahday Script', cursive;
  font-size: 3rem;
  color: white;
}

.shop-subtitle {
  text-align: center;
  font-family: arial;
  font-size: 1rem;
  font-style: italic;
  color: white;
}

.main-shop {
  height: 100vh;
}





.bg-ecailles-dark{
  background-image: url(../images/texture-ecailles-dark.jpg);
  background-repeat: repeat;
  background-size: 60px 60px;
}
.bg-ecailles-light{
  background-image: url(../images/texture-ecailles-light.jpg);
  background-repeat: repeat;
  background-size: 60px 60px;
}

